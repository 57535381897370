.shopName {
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

:global(.mobile) {
    .shopIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: var(--xl-spacing);

        img {
            height: 152px;
            width: 152px;
            border-radius: 36px;
        }
    }
}

:global(.desktop) {

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .shopIcon {
        padding: 30px 10%;
        background: #FAFAFA;
        margin-top: var(--xxl-spacing);

        img.icon {
            height: 162px;
            width: 221px;
            border-radius: 50px;
        }

        .shopIconGrid {
            margin-left: var(--xl-spacing);
        }
    }
}
