span.show-more-less-clickable {
    cursor:pointer;
    text-decoration:underline;
}

span.show-more-less-clickable:hover{
    text-decoration:none;
}
.NoOrders_text__GS_qe {
    color: var(--black50);
    padding-top: 20px;
}

.mobile .common_catalogs__b4FUg {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--black15);
        padding-bottom: var(--l-spacing);
        margin-bottom: var(--l-spacing);
    }

    .mobile .common_catalogMore__wBTK9 {
        background: #F4F4F4 !important;
        height: 98px;
        width: 98px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
    }

    .mobile .common_allowLeft__STtsD {
        margin-left: auto !important;
    }

    .mobile .common_catalog__v8CZI {
        margin: var(--m-spacing) 0;
        width: 33%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mobile .common_catalog__v8CZI .common_catalogImg__rfdli {
            height: 98px;
            width: 98px;
            border-radius: 36px;
            color: var(--black);
            font-size: var(--h5-font-size);
            font-weight: var(--semi-bold);
            -o-object-fit: contain;
               object-fit: contain;
        }

    .mobile .common_catalog__v8CZI .common_noCatalogImg__PBAJm {
            width: 98px;
        }

    .mobile .common_catalog__v8CZI .common_catalogLabel__PG5La {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--m-font-size);
            color: var(--black60);
        }

.desktop .common_catalogs__b4FUg {
        border-top: 1px solid var(--black15);
        border-bottom: 1px solid var(--black15);
        padding: 40px 20px;
        display: flex;
        flex-direction: row;
    }

.desktop .common_catalogs__b4FUg .common_slideButton__fs1uw:first-child {
            margin-right: var(--l-spacing);
        }

.desktop .common_catalogs__b4FUg .common_slideButton__fs1uw:last-child {
            margin-left: var(--l-spacing);
        }

.desktop .common_catalog__v8CZI {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 142px;
    }

.desktop .common_catalog__v8CZI .common_catalogImg__rfdli {
            height: 142px;
            width: 142px;
            border-radius: 50px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            -o-object-fit: contain;
               object-fit: contain;
        }

.desktop .common_catalog__v8CZI .common_noCatalogImg__PBAJm {
            width: 142px;
        }

.desktop .common_catalog__v8CZI .common_catalogLabel__PG5La {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--d-font-size);
            color: var(--black80);
        }

.desktop .common_catalogMore__wBTK9 {
        background: #F4F4F4 !important;
        height: 142px;
        width: 142px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }

.common_templates__MvD2V .common_slideButton__fs1uw {
        position: relative;
        z-index: 10
    }

.common_templates__MvD2V .common_slideButton__fs1uw:last-child {
            top: -120px;
            left: calc(100% - 40px);
        }

.common_templates__MvD2V .common_slideButton__fs1uw:first-child {
            top: 120px;
            left: 5px;
        }

@media screen and (max-width: 419px) {

.common_templates__MvD2V {
        margin-top: -20px;
        margin-bottom: -40px
}
    }

.common_template__iwA_f {
    width: 100%;
}

.common_template__iwA_f .common_templateImage__Vifrq {
        height: 200px;
        padding-left: var(--l-spacing);
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        align-items: center
    }

@media screen and (min-width: 420px) {

    .common_template__iwA_f .common_templateImage__Vifrq {
            height: 200px;
            padding: 0 var(--xxl-spacing)
    }
        }

.common_template__iwA_f .common_tplTitle__BDa69 {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

.common_template__iwA_f .common_tplDesc__rzEVQ {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }

.common_heading__FZQXj {
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin: var(--xxl-spacing) var(--xl-spacing) var(--l-spacing);
}

.common_viewAll__31JsP {
    padding: var(--l-spacing) var(--m-spacing);
 }
@media screen and (min-width: 420px) {
    .common_viewAll__31JsP {
        padding: 40px 40px;
 }
    }
.common_viewAll__31JsP .common_head__gaHhc {
        margin: var(--l-spacing) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
.common_viewAll__31JsP .common_name__uBZOs {
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        color: var(--black);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB {
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB span {
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP .common_footer__MMjiX {
        margin: var(--l-spacing) 0;
        font-weight: var(--semi-bold);
        text-align: center;
    }
.common_viewAll__31JsP .common_footer__MMjiX span {
            font-size: var(--d-font-size);
            color: var(--primary);
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP {
 
 
    border-bottom: 1px solid var(--black15);
    padding-bottom: var(--m-spacing);
}
 

.ProductColors_outer__8iSRi {
    width: 20px;
    height: 20px;
    margin-right: 16px !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ProductColors_outline__pVIda {
    outline-offset: 2px;
    outline-style: solid !important;
}

.ProductColors_link__kywcM {
    font-size: var(--d-font-size);
    border-bottom: 1px solid;
    cursor: pointer;
}

.ProductTags_container__PQEmU {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ProductTags_button__yeRpH {
    border-radius: var(--border-radius);
    padding: var(--xs-spacing);
    margin: var(--xxs-spacing) var(--s-spacing) var(--xs-spacing) 0;
    cursor: pointer;
}
.GetPrice_decimal__248ah {
    font-size: var(--xxs-font-size) !important;
    font-weight: var(--medium-bold) !important;
}

.ProductCard_container__zJ4xY {
    margin-bottom: var(--m-spacing);
}

.ProductCard_imgContainer__g9_7E {
    border: 1px solid var(--black05);
    border-radius: var(--s-border-radius);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;

    width: 162px;
    height: 162px
}

@media screen and (min-width: 420px) {

.ProductCard_imgContainer__g9_7E {
        width: 326px;
        height: 326px
        /* z-index: -99999 !important */
}
    }

.ProductCard_oos__qaQlQ {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px
}

@media screen and (min-width: 420px) {

.ProductCard_oos__qaQlQ {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing)
}
    }

.ProductCard_img__T4fSl {
    width: 162px;
    height: 162px;
    border-radius: var(--s-border-radius);
    -o-object-fit: cover;
       object-fit: cover
}

@media screen and (min-width: 420px) {

.ProductCard_img__T4fSl {
        width: 326px;
        height: 326px
}
    }

.ProductCard_click__k1CCl {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ProductCard_name__bXJ_1 {
    margin-top: var(--m-spacing);
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%
}

@media screen and (min-width: 420px) {

.ProductCard_name__bXJ_1 {
        margin-bottom: var(--l-spacing)
}
    }

.ProductCard_price__75YtV,
.ProductCard_price2__WMBvc {
    margin-bottom: var(--xs-spacing);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%
}

@media screen and (min-width: 420px) {

.ProductCard_price__75YtV,
.ProductCard_price2__WMBvc {
        min-width: 60%;
        width: 60%;
        max-width: 80%
}
    }


.ProductCard_price2__WMBvc {
    margin-bottom: var(--s-spacing);
}

.ProductCard_amount__sHG9m,
.ProductCard_discount__345dx {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.ProductCard_amount__sHG9m span {
        font-size: var(--s-font-size);
    }

.ProductCard_discount__345dx {
    font-size: var(--s-font-size);
}

.ProductCard_originalAmount__Bu3gc {
    font-size: var(--s-font-size);
    color: var(--black50);
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.ProductCard_discount__345dx {
    color: var(--green);
}

.ProductCard_unit__kKj3d {
    color: var(--black50);
    text-transform: capitalize;
}

.Skeleton_container__Pu6xx {
    padding: var(--s-spacing) var(--m-spacing);
}

.Skeleton_main__2A792 {

}

.Skeleton_flashCard__QhXos {

}

.Skeleton_first__m_3FN {

}

.Skeleton_second__UakWt {

}

.DisablePopup_dialog__N9d4w{
    /* width: 586px !important; */
    height: 363px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important
}

@media screen and (max-width: 419px) {

.DisablePopup_dialog__N9d4w{
        font-size: var(--m-font-size);
        width: 100% !important;
        height: 11rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important
}
     }

.DisablePopup_image__dVuDn{
    text-align: center;
}

.DisablePopup_img__eoI9z{

    height:  76px;
    width:  76px
}

@media screen and (max-width: 419px) {

.DisablePopup_img__eoI9z{
        height:  42px !important;
        width:  42px !important
}
     }

.DisablePopup_content__JafNB{
    /* height: 87px; */
    margin-top: 28px

}

@media screen and (max-width: 419px) {

.DisablePopup_content__JafNB{
        margin-top: 18px

} 
    }

.DisablePopup_wrnText__2k7io{

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-left:20%;
    padding-right:20%;
    color: #212121
}

@media screen and (max-width: 419px) {

.DisablePopup_wrnText__2k7io{
        font-size: 12px !important;
        line-height: 16px !important
}
     }
.store_shopName__R1scB {
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

.mobile .store_shopIcon__Q_b6V {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: var(--xl-spacing);
    }

.mobile .store_shopIcon__Q_b6V img {
            height: 152px;
            width: 152px;
            border-radius: 36px;
        }

.desktop .store_container__GQxOL {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

.desktop .store_shopIcon__Q_b6V {
        padding: 30px 10%;
        background: #FAFAFA;
        margin-top: var(--xxl-spacing);
    }

.desktop .store_shopIcon__Q_b6V img.store_icon__KT6R6 {
            height: 162px;
            width: 221px;
            border-radius: 50px;
        }

.desktop .store_shopIcon__Q_b6V .store_shopIconGrid__6cYQd {
            margin-left: var(--xl-spacing);
        }

