.container {
    margin-bottom: var(--m-spacing);
}

.imgContainer {
    border: 1px solid var(--black05);
    border-radius: var(--s-border-radius);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;

    width: 162px;
    height: 162px;

    @media (--desktop) {
        width: 326px;
        height: 326px;
        /* z-index: -99999 !important */
    }
}

.oos {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px;

    @media (--desktop) {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing);
    }
}

.img {
    width: 162px;
    height: 162px;
    border-radius: var(--s-border-radius);
    object-fit: cover;

    @media (--desktop) {
        width: 326px;
        height: 326px;
    }
}

.click {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.name {
    margin-top: var(--m-spacing);
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    @media (--desktop) {
        margin-bottom: var(--l-spacing);
    }
}

.price,
.price2 {
    margin-bottom: var(--xs-spacing);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    @media (--desktop) {
        min-width: 60%;
        width: 60%;
        max-width: 80%;
    }
}


.price2 {
    margin-bottom: var(--s-spacing);
}

.amount,
.discount {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.amount {
    span {
        font-size: var(--s-font-size);
    }
}

.discount {
    font-size: var(--s-font-size);
}

.originalAmount {
    font-size: var(--s-font-size);
    color: var(--black50);
    text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.discount {
    color: var(--green);
}

.unit {
    color: var(--black50);
    text-transform: capitalize;
}
