:global(.mobile) {

    .catalogs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--black15);
        padding-bottom: var(--l-spacing);
        margin-bottom: var(--l-spacing);
    }

    .catalogMore {
        background: #F4F4F4 !important;
        height: 98px;
        width: 98px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
    }

    .allowLeft {
        margin-left: auto !important;
    }

    .catalog {
        margin: var(--m-spacing) 0;
        width: 33%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .catalogImg {
            height: 98px;
            width: 98px;
            border-radius: 36px;
            color: var(--black);
            font-size: var(--h5-font-size);
            font-weight: var(--semi-bold);
            object-fit: contain;
        }

        .noCatalogImg {
            width: 98px;
        }

        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--m-font-size);
            color: var(--black60);
        }
    }
}

:global(.desktop) {

    .catalogs {
        border-top: 1px solid var(--black15);
        border-bottom: 1px solid var(--black15);
        padding: 40px 20px;
        display: flex;
        flex-direction: row;

        .slideButton:first-child {
            margin-right: var(--l-spacing);
        }

        .slideButton:last-child {
            margin-left: var(--l-spacing);
        }
    }

    .catalog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 142px;

        .catalogImg {
            height: 142px;
            width: 142px;
            border-radius: 50px;
            color: var(--black);
            font-size: var(--h4-font-size);
            font-weight: var(--semi-bold);
            object-fit: contain;
        }

        .noCatalogImg {
            width: 142px;
        }

        .catalogLabel {
            margin-top: var(--s-spacing);
            font-weight: var(--medium-bold);
            font-size: var(--d-font-size);
            color: var(--black80);
        }
    }


    .catalogMore {
        background: #F4F4F4 !important;
        height: 142px;
        width: 142px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
}

.templates {
    .slideButton {
        position: relative;
        z-index: 10;

        &:last-child {
            top: -120px;
            left: calc(100% - 40px);
        }

        &:first-child {
            top: 120px;
            left: 5px;
        }
    }

    @media (--phone) {
        margin-top: -20px;
        margin-bottom: -40px;
    }
}

.template {
    width: 100%;

    .templateImage {
        height: 200px;
        padding-left: var(--l-spacing);
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        align-items: center;
        @media (--desktop) {
            height: 200px;
            padding: 0 var(--xxl-spacing);
        }
    }

    .tplTitle {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

    .tplDesc {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }
}

.heading {
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin: var(--xxl-spacing) var(--xl-spacing) var(--l-spacing);
}
